<!-- =========================================================================================
  File Name: ItemGridView.vue
  Description: Item Component - Grid VIew
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <!-- <div class="item-grid-view vx-row match-height">
      <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
  <vx-card class="grid-view-item mb-base overflow-hidden" v-on="$listeners">
    <template slot="no-body">

      <!-- ITEM IMAGE -->
      <div class="no-padding item-img-container bg-white pt-4 flex items-center justify-center cursor-pointer" @click="redirectToService">
        <img :src="item.image" :alt="item.name" class="no-padding grid-view-img px-4 ">
      </div>
      <div class="px-4">
        <div class="my-4">
          <h6 class="text-center truncate font-semibold mb-1 hover:text-primary cursor-pointer" @click="redirectToService">{{ item.name }}</h6>
        </div>
      </div>

      <!-- SLOT: ACTION BUTTONS -->
      <slot name="action-buttons" />
    </template>
  </vx-card>
  <!--  </div>
</div> -->
</template>

<script>
export default{
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    redirectToService() {
      this.$gtag.event('srvs_entry', {
        event_category: 'services',
        event_label: this.item.name
      });

      location.href = this.item.redirect;
    }

  }
}
</script>

<style lang="scss" scoped>

.no-padding {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.grid-view-item {
  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: .35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

    .grid-view-img{
      opacity: 0.9;
    }
  }
}
</style>
